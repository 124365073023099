<template>
  <v-dialog
    v-model="dialog"
    class="dialog font-weight-medium"
    max-width="1000"
    persistent
  >
    <v-card class="card pt-10 text-center">
      <h2>{{ $t("create_manually.title") }}</h2>
      <v-row class="my-5">
        <v-col md="6" class="text-left">
          <h5>{{ $t("create_manually.headlines") }}</h5>
          <div
            v-for="(item, index) of headlinesInputs"
            :key="index"
            class="d-flex"
          >
            <div style="width: 90%">
              <input
                v-model="item.value"
                maxlength="30"
                class="input-border textarea-style pa-3 font-family-raleway-medium"
                :class="
                  isHaveSameContent(item, index, 'headlinesInputs')
                    ? 'error-border'
                    : ''
                "
              />
              <div class="d-flex align-center justify-space-between">
                <span
                  v-if="isHaveSameContent(item, index, 'headlinesInputs')"
                  class="ml-2"
                  style="font-size: 12px; color: red"
                  >{{ $t("create_manually.duplicate_assets") }}</span
                >
                <span v-else />
                <div class="text-right">{{ item.value.length }}/30</div>
              </div>
            </div>
            <v-tooltip top v-if="headlinesInputs.length > 3">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mt-2 ml-2"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="clickRemoveHeadButton(index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("create_manually.remove") }}</span>
            </v-tooltip>
          </div>
          <div class="text-right mt-2">
            <v-btn
              text
              class="text-none"
              color="primary"
              @click="clickAdHeadlines"
            >
              <v-icon small>mdi-plus</v-icon>
              <span>{{ $t("create_manually.adHeadline") }}</span>
            </v-btn>
          </div>
        </v-col>
        <v-col md="6" class="text-left">
          <h5>{{ $t("create_manually.descriptions") }}</h5>
          <div
            v-for="(item, index) of descriptionsInputs"
            :key="index"
            class="d-flex"
          >
            <div style="width: 90%">
              <textarea
                v-model="item.value"
                maxlength="90"
                class="input-border textarea-style pa-3 font-family-raleway-medium"
                :class="
                  isHaveSameContent(item, index, 'descriptionsInputs')
                    ? 'error-border'
                    : ''
                "
              />
              <div class="d-flex align-center justify-space-between">
                <span
                  v-if="isHaveSameContent(item, index, 'descriptionsInputs')"
                  class="ml-2"
                  style="font-size: 12px; color: red"
                  >{{ $t("create_manually.duplicate_assets") }}</span
                >
                <span v-else />
                <div class="text-right">{{ item.value.length }}/90</div>
              </div>
            </div>
            <v-tooltip top v-if="descriptionsInputs.length > 2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mt-2 ml-2"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="clickRemoveDescButton(index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("create_manually.remove") }}</span>
            </v-tooltip>
          </div>
          <div class="text-right mt-2">
            <v-btn
              text
              class="text-none"
              color="primary"
              @click="clickAdDescription"
            >
              <v-icon small>mdi-plus</v-icon>
              <span>{{ $t("create_manually.adDescription") }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <v-chip
        v-if="errorText"
        style="width: 100%; background-color: rgb(255, 227, 227)"
        class="py-5 mt-1 justify-center"
        size="large"
        text-color="red"
        label
      >
        <span class="font-family-raleway-medium">
          {{ errorText }}
        </span>
      </v-chip>
      <v-card-actions class="text-right mb-0">
        <v-col class="font-family-raleway-medium" cols="12" md="5" />
        <v-col
          class="font-family-raleway-medium text-right mb-0"
          cols="12"
          md="7"
        >
          <v-btn
            class="back-btn text-decoration-underline mr-5"
            @click="$emit('close')"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="button font-weight-bold font-family-raleway-medium px-16"
            height="60"
            :disabled="getNullInput"
            :loading="isSaving"
            @click="validateAds"
          >
            {{ $t("save_button") }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { campaignService } from "@/services/campaign";

export default {
  name: "CreateManuallyAds",
  props: {
    dialog: Boolean,
    adsData: Object,
  },
  data() {
    return {
      errorText: "",
      isCreating: false,
      isSaving: false,
      headlinesInputs: [{ value: "" }, { value: "" }, { value: "" }],
      descriptionsInputs: [{ value: "" }, { value: "" }],
    };
  },
  computed: {
    getNullInput() {
      const findHeadItem = this.headlinesInputs.find(
        (item) => item.value === ""
      );
      const findDescItem = this.descriptionsInputs.find(
        (item) => item.value === ""
      );
      return !!findHeadItem || !!findDescItem;
    },
    isHaveSameContent: function () {
      return function (item, index, items) {
        if (item.value === "") return false;
        const filteredItems = this[items].filter((i, ind) => ind !== index);
        return !!filteredItems.find(
          (i) => i.value.trim() === item.value.trim()
        );
      };
    },
  },
  methods: {
    clickAdHeadlines() {
      this.headlinesInputs.push({ value: "" });
    },
    clickAdDescription() {
      this.descriptionsInputs.push({ value: "" });
    },
    clickRemoveHeadButton(index) {
      this.headlinesInputs = this.headlinesInputs.filter(
        (item, inx) => index !== inx
      );
    },
    clickRemoveDescButton(index) {
      this.descriptionsInputs = this.descriptionsInputs.filter(
        (item, inx) => index !== inx
      );
    },
    async validateAds() {
      const headlines = this.headlinesInputs.map((item) => item.value);
      const descriptions = this.descriptionsInputs.map((item) => item.value);
      this.isSaving = true;
      try {
        await campaignService.validateAds({
          headlines,
          descriptions,
        });
        let payload = {
          headlines: headlines.map((item) => {
            return {
              title: item,
            };
          }),
          descriptions: descriptions.map((item) => {
            return {
              title: item,
            };
          }),
        };
        this.$emit("setData", payload);
        this.closeModal();
      } catch (e) {
        this.errorText = e.response?.data?.errorCode;
      }
      this.isSaving = false;
    },
    closeModal() {
      this.headlinesInputs = [{ value: "" }, { value: "" }, { value: "" }];
      this.headlinesInputs = [{ value: "" }, { value: "" }];
      this.errorText = "";
      this.$emit("close");
    },
  },
};
</script>
